<template>
    <div>
        <v-card
                v-for="v in listData" :key="v.id"
                outlined
                class="d-flex justify-space-between align-center px-4"
        >
            <!-- 主要資料 -->
            <div class="d-flex justify-center">
                <list-row-main-select
                    :list-key="listKey"
                    :row="v"
                ></list-row-main-select>
            </div>

            <div>{{ v.name || v.product[0].name }}</div>
            <div>
                <listDataAction
                    :row="v"
                    :list-key="listKey"
                ></listDataAction>
            </div>
        </v-card>
    </div>
</template>

<script lang="babel" type="text/babel">
import listRow from '@/components/list/mixins/listRow'


export default {
    mixins: [listRow],
    props: {
        listKey: String,
    },
    computed: {
        hasSelectFeature() {
            return this.$store.getters[`list/${this.listKey}/hasSelectFeature`]
        },
        listData() {
            return this.$store.getters[`list/${this.listKey}/data`]
        },
        listHasSelected() {
            return this.$store.getters[`list/${this.listKey}/hasSelected`]
        },
        usedListEmpty() {
            return this.$store.getters[`list/${this.listKey}/usedListEmpty`]
        },
    },
    components: {
        listRowMainSelect: () => import('components/list/listRowMainSelect.vue'),
        listDataAction: () => import('@/components/list/listDataAction.vue'),
    },
}
</script>

<style lang="sass" type="text/sass" scoped></style>